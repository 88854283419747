import * as mdb from 'mdb-ui-kit';
// import EcommerceGallery from '../../node_modules/mdb-ui-kit/plugins/js/ecommerce-gallery.min'; // plugin
// import '../../node_modules/mdb-ui-kit/plugins/js/ecommerce-gallery.min'; // module
import MultiCarousel from '../../node_modules/mdb-ui-kit/plugins/js/multi-carousel.min'; // plugin
// import * as Lightbox from './modules/lightbox.min';

window.mdb = mdb // add lib as a global object
// window.Parallax = Parallax // add lib as a global object
// window.EcommerceGallery = EcommerceGallery // add lib as a global object
window.MultiCarousel = MultiCarousel // add lib as a global object

export default {
  mdb,
//   Lightbox,
//   EcommerceGallery,
  MultiCarousel
};
